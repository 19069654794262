const SocialIconData = [
  {
    image: "/footerImages/whatsapp.png",
    url: "https://wa.me/972526146659",
  },

  {
    image: "/footerImages/youtube.png",
    url: "https://www.youtube.com/@tsadkoyahdav",
  },
];
export default SocialIconData;
