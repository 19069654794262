const dailyTextsData = [
  {
    text: "כשהמעלה הנשמתית עולה, מתאגדים התלמודים יחד",
    source: "הרב קוק, שמונה קבצים ח קמב",
  },
  {
    text: "אנחנו יכולים להמשיך טל אורות של אויר ארץ חמדה... עד שילך עמנו אור הבבלי להתאחד עם התלמוד הירושלמי ויחדיו יאירו באור שבעתים. ואל זה אנחנו צריכים לשאוף.",
    source: "הרב קוק, אגרת צו",
  },
  {
    text: "בארץ ישראל שהיא מקום הנבואה, יש רושם לשפע הנבואה בסדר הלימוד, ואם ממזגים את התלמוד הירושלמי עם התלמוד הבבלי, מביאים בזה אורה וברכה ומקרבים את הגאולה.",
    source: "הרב חרלפ, מעייני הישועה לט",
  },
  {
    text: "על הגאולה- זה תלמוד ירושלמי. ועל התמורה- זה תלמוד בבלי",
    source: "זוהר חדש, רות",
  },
  {
    text: "והאור- זה התלמוד הירושלמי דנהיר נהורא דאורייתא",
    source: "זוהר חדש, איכה",
  },
  {
    text: "כל גמרא שלומדים בבלי צריך ללמוד בירושלמי",
    source: "הרב חיים קנייבסקי",
  },
];
export default dailyTextsData;
