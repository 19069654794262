
const playerVars = {
    autoplay: 0,
    controls: 0, // Hide default controls
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  };
  
  export default playerVars;
  